import React from 'react'

export const MODALS = {
  examDetails: {
    component: React.lazy(() => {
      return import('~/components/Modals/ExamDetailsModal')
    })
  },
  downloadApps: {
    component: React.lazy(() => {
      return import('~/components/Modals/DownloadAppsModal')
    })
  }
} as const
