'use client'

import React from 'react'
import { Modal } from '@nextui-org/react'
import { ClientOnly } from 'remix-utils/client-only'
import { create } from 'zustand'
import { MODALS } from './modals.constants'

type Modals = typeof MODALS
type ModalKey = keyof Modals

const initialState: {
  component: Modals[ModalKey]['component'] | null
  componentName: ModalKey | null
  isOpen: boolean
  componentProps: React.ComponentProps<Modals[ModalKey]['component']> | null
} = {
  component: null,
  componentProps: null,
  isOpen: false,
  componentName: null
}

export const useModal = create<
  typeof initialState & {
    closeModal: () => void
    showModal: <T extends ModalKey>(
      componentName: T,
      componentProps: React.ComponentProps<Modals[T]['component']>
    ) => void
  }
>((set) => {
  return {
    ...initialState,
    closeModal: () => {
      set({
        isOpen: false
      })
    },
    showModal: <T extends ModalKey>(
      componentName: T,
      componentProps: React.ComponentProps<Modals[T]['component']>
    ) => {
      const modal = MODALS[componentName]

      if (!modal) {
        throw new Error(
          `The modal '${componentName}' has no existing component`
        )
      }

      set({
        component: modal.component,
        componentName: componentName as T,
        componentProps,
        isOpen: true
      })
    }
  }
})

export function useShowModal() {
  return useModal((state) => {
    return state.showModal
  })
}

export function useCloseModal() {
  return useModal((state) => {
    return state.closeModal
  })
}

export const ModalProvider = () => {
  const {
    component: Component,
    componentProps,
    isOpen,
    closeModal
  } = useModal()

  return (
    <ClientOnly>
      {() => {
        return (
          <React.Suspense fallback={null}>
            <Modal
              isOpen={isOpen}
              scrollBehavior="inside"
              onClose={closeModal}
              backdrop="blur"
              classNames={{
                base: 'rounded-lg bg-white max-w-4xl',
                header: 'container-gutters-x sm:px-14 pt-14 sm:pb-10',
                body: 'container-gutters-x sm:px-14 pb-14',
                backdrop: 'bg-black/70 !backdrop-blur-sm backdrop-opacity-80',
                closeButton: 'max-sm:bg-black/60 max-sm:text-white'
              }}
            >
              {Component && componentProps ? (
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                <Component {...(componentProps as any)} />
              ) : (
                <div />
              )}
            </Modal>
          </React.Suspense>
        )
      }}
    </ClientOnly>
  )
}
